<template>
	<div class="wrap">
		<div class="b-w flex f-ai-c doctor">
			<img :src="formData.doctorAvatar" alt="">
			<div class="doc-info">
				<h1>{{formData.doctorName}}</h1>
				<div class="doc-dept">
					<span class="pr">{{formData.deptName}}</span>
					<span>{{formData.titleName}}</span>
				</div>
			</div>
		</div>
		<div class="b-w appr">
			<span class="title">患者评价</span>
			<van-rate :readonly="formData.appraiseId ? true : false" v-model="star" :size="25" color="#ffd21e" void-icon="star" void-color="#eee" />
			<van-field
				v-model="content"
				autosize
				rows="4"
				:readonly="formData.appraiseId ? true : false"
				type="textarea"
				maxlength="200"
				placeholder="谈谈您这次就诊对医生的印象吧～"
				show-word-limit
				class="content"
			/>
		</div>
		<s-button v-if="!formData.appraiseId" class="sub-btn" btnText="匿名提交" @s-click="submit"></s-button>
	</div>
</template>
<script>
import sButton from '@/views/common/button.vue';
import { appraiseAdd, appraiseSearch, consultationDetail } from '@/request/api/user';

export default {
	data () {
		return {
			star: 0,
			content: '',
			serviceType: '',
			formData: {}
		};
	},
	components: { sButton },
	created () {
		this.formData = { ...this.$route.query };
		this.getConsultationDetail();
		if (this.$route.query.appraiseId) this.getDetail();
	},
	methods: {
		getConsultationDetail () {
			consultationDetail({ doctorId: this.formData.doctorId, patientId: this.formData.patientId, id: this.formData.consultationId }).then(data => {
				if (data && data === 'retry') {
					this.getConsultationDetail();
				} else if (data && data.id) {
					this.serviceType = data.type;
				}
			});
		},
		getDetail () {
			appraiseSearch({ consultationId: +this.formData.consultationId }).then(data => {
				if (data && data === 'retry') {
					this.getDetail();
				} else if (data && data.id) {
					this.content = data.content;
					this.star = data.star;
					document.title = '评价详情';
				}
			});
		},
		submit () {
			appraiseAdd({
				doctorId: +this.formData.doctorId,
				consultationId: +this.formData.consultationId,
				content: this.content,
				star: this.star,
				serviceType: 1
			}).then(data => {
				if (data && data === 'retry') {
					this.submit();
				} else if (data && data.ok === 1) {
					this.$root.go(-1);
				}
			});
		}
	}
};
</script>
<style lang="less" scoped>
	.doctor {
		margin-top: 10px;
		height: 90px;
		img {
			width: 60px;
			height: 60px;
			border-radius: 50%;
			object-fit: cover;
			margin-left: 15px;
			margin-right: 10px;
		}
		.doc-info {
			h1 {
				font-size: 18px;
				font-family: PingFangSC-Medium, PingFang SC;
				font-weight: 500;
				color: #3B3D40;
			}
			.doc-dept {
				font-size: 12px;
				font-family: PingFangSC-Regular, PingFang SC;
				font-weight: 400;
				color: #8B8B8B;
				span:nth-child(1) {
					margin-right: 11px;
					&::after {
						content: '';
						width: 1px;
						height: 5px;
						background-color: #EAEAEA;
						display: inline-block;
						position: absolute;
						right: -6px;
						top: 50%;
						transform: translateY(-50%);
					}
				}
			}
		}
	}
	.appr {
		margin-top: 10px;
		padding: 25px 15px 0;
		box-sizing: border-box;
		.title {
			font-size: 14px;
			font-family: PingFangSC-Medium, PingFang SC;
			font-weight: 500;
			color: #3B3D40;
			display: block;
			margin-bottom: 15px;
		}
		.content {
			margin-top: 15px;
			padding-left: 0;
			padding-right: 0;
			/deep/ textarea {
				background: #F7F7F7 !important;
				padding-left: 10px;
				padding-top: 10px;
			}
		}
	}
</style>